body, html {
  margin: 0;
  padding: 0;
  height: calc(100% - 0px); /* Subtract the total padding from the height */
  width: 100%;
  /*background-color: #1c1c23;*/
  background-color: pink;
  overflow: hidden;
  text-transform: uppercase;
  font-family: Roboto, sans-serif;
  font-weight: 700;
}
.dashboard {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  justify-content: space-around;
  /*width: 100%;*/
  /*height: calc(100% - 20px); !* Subtract the total padding from the height *!*/
  background-color: grey;
  padding: 10px;
  grid-gap: 10px;
}

.dashboard .left {
  grid-column: 1;
  grid-row: span 3;
  display: grid;
  grid-template-rows: repeat(3, calc(31.9vh - 0px / 3));
  gap: 10px;

}

.dashboard .center {
  grid-column: 2 / span 2;
  grid-row: 1 / span 2;
  background-color: red;
}

.dashboard .middle {
  grid-column: 2 / span 2;
  grid-row: 3;
  background-color: pink;
}

.dashboard .right {
  grid-column: 4;
  grid-row: span 3;
  background-color: aqua;
}
.card-outer {
  background-color:#31323D;
  border-radius: 10px;
  padding: 10px;
  color: white;
}

.card-outer2 {
  box-sizing: border-box;
  background-color:#31323D;
  border-radius: 10px;
  padding: 5px;
  color: white;
  height: 100%;
}
.card-outer3 {
  background-color:#31323D;
  border-radius: 10px;
  padding: 5px;
  color: white;
  height: 99%;
}
.nested-card {
  background-color: #434352;
  border-radius: 10px;
  padding: 10px;
  color: white;
  /*height: 100%;*/
  text-transform: uppercase;
  text-align: center;
}
.text-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 0 15px 0;
  font-size: 20px;
}
.text-header-center {
  display: flex;
  justify-content: center;
  padding: 10px 0 15px 0;
  font-size: 20px;
}
.head-left{
  padding-left: 10px;
}
.head-right{
  padding-right: 10px;
}
.grid-container {
  display: grid;
  grid-template-columns: 4fr 6fr;
  grid-template-rows: auto auto;
  grid-gap: 10px;
}
.grid-container2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 10px;
  height: 100%;
  /*height: 530px;*/
}
.sales-progression {
  grid-column: 1 / span 2;
  grid-row: 3;
  height: 62%;

}
.regional-national{

}
.trailing-success-chart{
  /*height: 206px;*/
}
.grid-container3 {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(11, auto);
  grid-gap: 10px;
  height: 94%; /* Add this line */
}

.salesman-card {
  background-color: #434352;
  border-radius: 5px;
  padding: 10px;
  color: white;
  text-transform: uppercase;
  text-align: center;
  grid-row: span 1;
}

.first-place {
  grid-row: span 2;
}
.salesman-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.salesman-card .salesman-image {
  /*width: 50px;*/
  /*height: 50px;*/
  border-radius: 50%;
}

.salesman-card .salesman-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.salesman-card.first-place {
  grid-row: span 3;
}

.salesman-card.first-place .salesman-image {
  width: 100px;
  height: 100px; /* 3x the height */
}

.salesman-card.first-place .salesman-info {
  /*align-items: flex-end;*/
  text-align: left;
}
.salesman-card.first-place .salesman-info .numbers {
  color: #76ED4D;
  display: inline;
}
/* Styles for 2nd to 10th salesman cards */
/*.salesman-card:not(.first-place) .salesman-image {*/
/*  width: 40px;*/
/*  height: 40px;*/
/*}*/

.salesman-card:not(.first-place) .salesman-info {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.salesman-card:not(.first-place) {
  display: flex;
  align-items: center;
}

.salesman-card:not(.first-place) .salesman-image {
  width: 40px;
  height: 40px;
}

.salesman-card:not(.first-place) .salesman-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}

.salesman-card:not(.first-place) .salesman-info .salesman-name {
  margin-right: auto;
}
._half-circular-progress text {
  display: none;
}
.year-goal-chart{
  margin-top: 35px;
  position: relative; /* Add this line */
}
.year-benchmark-indicator {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid white;
}
